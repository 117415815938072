import { get } from "@rails/request.js";
import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller<HTMLFormElement> {
  static targets = [
    "genericNotes",
    "offeredBy",
    "consentToShare",
    "supportsBlock",
    "actionsBlock",
    "supportForm",
    "autoSaveSupport",
    "allowLeavePage",
    "form"
  ];
  declare readonly genericNotesTargets: HTMLFieldSetElement[];
  declare readonly offeredByTargets: HTMLFieldSetElement[];
  declare readonly consentToShareTargets: HTMLFieldSetElement[];
  declare readonly supportsBlockTargets: HTMLFieldSetElement[];
  declare readonly actionsBlockTargets: HTMLFieldSetElement[];
  declare readonly supportFormTargets: HTMLFormElement[];
  declare readonly autoSaveSupportTargets: HTMLFormElement[];
  // you shouldn't use this target for the save buttons, because it will be used for the form submit
  declare readonly allowLeavePageTargets: HTMLFormElement[];
  declare readonly formTargets: HTMLFormElement[];

  declare __changeListener: () => void;
  declare isChanged: boolean;
  declare skipChangePage: boolean;
  connect() {
    console.debug("Signposting controller connected");

    // Not saved changes notification
    this.isChanged = false;
    this.skipChangePage = false
    this.__changeListener = () => {
      if (this.skipChangePage) {
        return;
      }
      if ($(this.element).data('draft') || this.isChanged) {
        return "Are you sure you want to leave?";
      }
    };

    this.#subscribeElements()

    $(window).on("beforeunload", this.__changeListener);
  }
  // Hack for the delete button
  allowLeavePageTargetConnected(element) {
    $(element).on("click.rails", () => {
      if ($(element).attr('data-confirm')) {
        return;
      }
      const prev = this.skipChangePage;
      this.skipChangePage = true;
      const formHandler = function () {
        $(window).off("turbo:frame-render", formHandler);
        this.skipChangePage = prev;
      };
      $(window).on("turbo:frame-render", formHandler);
    });
  }

  #potentiallyHaltLeave(evt) {
    const { currentTarget: t } = evt

    if (this.skipChangePage) return

    const errElement = document.getElementById('no-support-agency-error')
    if (this.agenciesSignposted.length == 0 && errElement?.childElementCount == 0) return

    if ($(this.element).data('draft') || this.isChanged || errElement?.childElementCount > 0) {
      evt.preventDefault()
      evt.stopPropagation()
      evt.stopImmediatePropagation()

      new Promise((resolve, _reject) => {
        $('#confirmation-leave-popup').modal('show')
        $('#confirmation-leave-popup [data-confirm-action="submit"]').on('click', () => {
          resolve(['submit', t])
        })

        $('#confirmation-leave-popup [data-confirm-action="leave"]').on('click', () => {
          resolve(['leave', t])
        })

        $('#confirmation-leave-popup').on('hide.bs.modal', () => {
          resolve(['noop', null])
        })

        }).then(([action, target]) => {
          switch (action) {
            case 'submit':
              $('#confirmation-leave-popup').modal('hide')

              const form = this.formTargets[0]
              
              const submitter = document.createElement('input')
              submitter.type = 'submit'
              submitter.hidden = true
              submitter.name = 'go_to'
              submitter.value = target.href

              form.appendChild(submitter)
              submitter.click()
              form.removeChild(submitter)
              break
            case 'leave':
              $('#confirmation-leave-popup').modal('hide')
              Turbo.visit(target.href, { action: 'advance' })
              break
            default:
              break
          }
        }).catch((_err) => {
          $('#confirmation-leave-popup').modal('hide')
        })
      
      return true;
    }
  }

  #subscribeElements() {
    for (const item of this.outsiders) {
      item.addEventListener('click', this.#potentiallyHaltLeave.bind(this))
      item.addEventListener('keydown', this.#potentiallyHaltLeave.bind(this))
    }
  }

  #unsubscribeElements() {
    for (const item of this.outsiders) {
      item.removeEventListener('click', this.#potentiallyHaltLeave)
      item.removeEventListener('keydown', this.#potentiallyHaltLeave)
    }
  }

  markAsChanged() {
    this.isChanged = true;
  }

  async followUpRecordedHandler(e) {
    const { supportId } = e.params;
    let url = "supports?follow_up_recorded=1";
    if (supportId) {
      url = `supports?editing_support_id=${supportId}&follow_up_recorded=1`;
    }
    await get(url, {
      headers: {
        "Turbo-Frame": "supports_list",
      },
    });
  }

  disconnect(): void {
    this.#unsubscribeElements()
    
    $(window).off("beforeunload", this.__changeListener);
  }

  offeredSelected(e) {
    if (
      $(this.element).find(
        ".supports-list:visible, [data-signposting-previous-param]:visible"
      ).length &&
      ![
        "offered_support",
        "offered_support_phone",
        "offered_support_email",
      ].includes(e.target.value)
    ) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      $("#delete_supports_popup").modal("show");
      return true;
    }

    const request = new FetchRequest($(this.formTargets)[0].method || 'post',
      $(this.formTargets).attr('action'),
      {
        body: JSON.stringify({
          signposting: {
            offered: e.target.value
          },
          auto_save: true
        }),
        headers: {
          'Content-Type': 'application/json',
          'Turbo-Frame': 'new_support_form'
        }
      }
    )
    request.perform();

    this.toggleRelatedBlocksByOfferedBy(e.target.value);
  }

  saveSupportForm(e) {
    if (this.supportFormTargets.length && $(this.supportsBlockTargets).filter(':not(.hidden)').length) {
      this.autoSaveSupport(e);
    } else {
      this.skipChangePage = true;
      const handler = () => {
        this.skipChangePage = false;
      }
      $(window).one("turbo:frame-render", handler);
    }
  }

  autoSaveSupport(e) {
    if (this.supportFormTargets.length && $(this.supportsBlockTargets).filter(':not(.hidden)').length) {
      e.preventDefault();
      e.stopPropagation();
      const saveButton = $(this.supportFormTargets).find(
        'button[name="submit_action"][value="autoSave"]'
      );
      if (!saveButton.attr("data-confirm-accepted") && saveButton.attr("data-turbo-confirm")) {
        const resumeHandler = () => {
          saveButton.off("rms:confirm-accept", resumeHandler);
          const formHandler = (ev) => {
            $(window).off("turbo:submit-end", formHandler);
            if (ev.originalEvent.detail.success) {
              // wait for the form to be removed from the DOM.
              const int = setInterval(() => {
                if (!this.supportFormTargets.length) {
                  clearInterval(int);
                  (e.submitter || e.target)?.click();
                }
              }, 10)
            }
          };
          $(window).on("turbo:submit-end", formHandler);
        };
        saveButton.on("rms:confirm-accept", resumeHandler);
        saveButton.on("rms:confirm-reject", function () {
          saveButton.off("rms:confirm-accept", resumeHandler);
        });
      } else {
        const formHandler = (ev) => {
          $(window).off("turbo:submit-end", formHandler);
          if (ev.originalEvent.detail.success) {
            // wait for the form to be removed from the DOM.
            const int = setInterval(() => {
              if (!this.supportFormTargets.length) {
                clearInterval(int);
                (e.submitter || e.target)?.click();
              }
            }, 10)
          }
        };
        $(window).on("turbo:submit-end", formHandler);
      }
      saveButton[0].click();
    }
  }

  private toggleRelatedBlocksByOfferedBy(offeredBy: string): void {
    const showGenericBlocks = !!offeredBy;
    [
      ...this.offeredByTargets,
      ...this.genericNotesTargets,
      ...this.actionsBlockTargets,
    ].forEach((el) => {
      if (el) {
        el.classList.toggle("hidden", !showGenericBlocks);
        el.disabled = !showGenericBlocks;
      }
    });
    const showConsentToShare = offeredBy && offeredBy !== "na";
    this.consentToShareTargets.forEach((el) => {
      if (el) {
        el.classList.toggle("hidden", !showConsentToShare);
        el.disabled = !showConsentToShare;
      }
    });
    const showSupportsBlock = [
      "offered_support",
      "offered_support_phone",
      "offered_support_email",
    ].includes(offeredBy);
    this.supportsBlockTargets.forEach((el) => {
      if (el) {
        el.classList.toggle("hidden", !showSupportsBlock);
        el.disabled = !showSupportsBlock;
      }
    });
  }

  get agenciesSignposted() {
    return this.element.querySelectorAll('#supports_list table tr')
  }

  get outsiders() {
    return Array.from(document.getElementsByTagName('a'))
      .filter((link) => this.includeElement(link))
      .filter((link) => link.dataset?.toggle !== 'dropdown')
  }

  includeElement = (element) => {
    return !this.formTargets[0].contains(element) || 
      (
        this.formTargets[0].contains(element) && 
        this.actionsBlockTargets[0].contains(element) &&
        element.dataset?.turboMethod !== 'delete'
      )
  }
}
